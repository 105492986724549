// Path: ./src/app/components/BestDealSubComponent/index.tsx
/**
 *
 * BestDealSubComponent
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import Button from '@mui/material/Button';
import { styled as stlyedMUI } from '@mui/material/styles';

import { GfPromotionDto } from '@swagger/typescript-fetch-goflyer';
import CheckIcon from '@mui/icons-material/Check';
import { messages } from 'locales/messages';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Imageloader from '../ImageLoader';
interface Props {
  startingDate: Date;
  onClick?: any;
  onClickCapture?: any;
  promotionShoppingList?:
    | string
    | undefined
    | GfPromotionDto
    | GfPromotionDto[];
  Image?: string;
  productName?: string;
  discountPrice?: number;
  price?: number;
  unit?: string;
  quantity?: number;
  isLoading?: boolean;
}

export const BestDealSubComponent: React.FunctionComponent<Props> = memo(
  props => {
    const { t } = useTranslation();
    return (
      <BottomDiv>
        <div>
          <Imageloader
            startingDate={props.startingDate}
            contain={true}
            height={'107px'}
            width="107px"
            url={props.Image}
          />
        </div>
        <ProductInfoDiv>
          <ProductHeading>{props.productName}</ProductHeading>
          <div>
            {props.quantity !== undefined && props.quantity > 1 ? (
              <ProductDiscountPrice>
                {props.quantity}{' '}
                {props.unit && (
                  <>
                    <PerUnit>{props.unit}</PerUnit>
                  </>
                )}{' '}
                for ${props.discountPrice}
              </ProductDiscountPrice>
            ) : (
              <>
                <ProductDiscountPrice>
                  ${props.discountPrice}{' '}
                  {props.unit && (
                    <>
                      <PerUnit>Per {props.unit}</PerUnit>
                    </>
                  )}
                </ProductDiscountPrice>{' '}
                {props?.price && (
                  <ProductPrice
                    style={{
                      display:
                        props.price &&
                        props.discountPrice &&
                        props.price >= props.discountPrice
                          ? 'inline'
                          : 'none',
                    }}
                  >
                    $ {props.price}
                  </ProductPrice>
                )}
              </>
            )}
          </div>
          {props?.onClick ? (
            <MuiButton
              data-testid="Best Deal Sub component add to shopping list"
              variant="contained"
              onClick={props.onClick}
              onClickCapture={props.onClickCapture}
              style={{
                backgroundColor:
                  props.promotionShoppingList === undefined
                    ? '#FF9500'
                    : 'white ',
                border: '2px solid #FF9500',
                boxShadow: 'none',
              }}
              disabled={props.isLoading}
            >
              {props.promotionShoppingList === undefined ? (
                t(messages.Add_To_Shopping_List())
              ) : (
                <>
                  {props.isLoading ? (
                    <CircularProgress size={25} />
                  ) : (
                    <MuiCheckIcon />
                  )}
                </>
              )}
            </MuiButton>
          ) : (
            <></>
          )}
        </ProductInfoDiv>
      </BottomDiv>
    );
  },
);

const MuiButton = stlyedMUI(Button)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  background: '#FF9500 0% 0% no-repeat padding-box',
  borderRadius: '10px',
  opacity: '1',
  padding: '10px',
  textAlign: 'left',
  font: 'normal normal medium 17px/20px ',
  fontFamily: 'SFProText',
  fontSize: '17px !important',
  letterSpacing: '-0.54px',
  color: '#FFFFFF ',
  height: '36px',
  textTransform: 'capitalize',
  width: '100%',
  '@media (max-width:320px)': {
    fontSize: '11px !important',
  },
  '@media (max-width:375px)': {
    fontSize: '15px',
  },
  '@media (min-width:624px)': {
    width: '200px !important',
  },
}));

const MuiCheckIcon = stlyedMUI(CheckIcon)(() => ({
  color: '#FF9500',
  fontWeight: 'bolder',
}));

const BottomDiv = styled.div`
  height: 137px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #80808012;
`;
const ProductDiv = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 107px !important;
  height: 107px;
  margin: 17px;
`;

const ProductInfoDiv = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
`;

const ProductHeading = styled.h3`
  margin: 0px;
  text-align: left;
  font: normal normal 600 15px/18px SFProText;
  letter-spacing: -0.48px;
  color: #000000;
  opacity: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const ProductDiscountPrice = styled.span`
  margin: 0px;
  text-align: left;
  font: normal normal bold 18px/16px SFProText;
  letter-spacing: 0px;
  color: #ff9500;
  opacity: 1;
  margin-right: 7px;
`;
const ProductPrice = styled.span`
  margin: 0px;
  text-align: left;
  text-decoration: line-through;
  font: normal normal medium 15px/16px;
  font-family: SFProText;
  letter-spacing: 0px;
  color: #89888d;
  opacity: 0.5;
`;

const PerUnit = styled.span`
  font-size: 10px;
`;
