// Path: ./src/app/components/NewPromotionDetailInfoComp/index.tsx
/**
 *
 * NewPromotionDetailInfoComp
 *
 */
import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { messages } from 'locales/messages';
import { IconButton, Typography } from '@mui/material';
import { GfImageDto, GfPromotionDto } from '@swagger/typescript-fetch-goflyer';
import noimage from '../../assets/No_Image.jpg';
import { Share } from '@capacitor/share';
import { Device } from '@capacitor/device';
import IosShareIcon from '@mui/icons-material/IosShare';

interface Props {
  title?: string;
  promoinfavlist?: GfPromotionDto | undefined;
  onClickAddToShoppingListButtonFnc?: any;
  productName?: string;
  storeGfImages?: GfImageDto[];
  merchantName?: string;
  DiscountPrice?: number;
  ActualPrice?: number;
  productDescription?: string;
  unit?: string;
  quantity?: number;
  promotionId?: string;
}

export const NewPromotionDetailInfoComp: React.FunctionComponent<Props> = memo(
  props => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t, i18n } = useTranslation();
    const [shareState, setShareState] = useState(false);
    const handleShare = async params => {
      await Share.share({
        title: `${params?.merchantName} - ${params?.productName}`,
        text: `${params?.productName} - $${params?.DiscountPrice}`,
        url: `https://goflyer.ca/promotiondetail?promotionId=${
          params.promotionId
        }&productName=${encodeURIComponent(params?.productName)}`, // TODO: will make goflyer.ca as an env variable later
        dialogTitle: 'Share with others',
      });
    };
    const handleDeviceType = async () => {
      const info = await Device.getInfo();
      if ((info && info.platform === 'android') || info.platform === 'ios') {
        setShareState(true);
      } else {
        setShareState(false);
      }
    };

    useEffect(() => {
      handleDeviceType();
    }, []);

    return (
      <WrapperFamilyDiv>
        <Typography
          color={'primary'}
          variant={'body2'}
          style={{ font: 'normal normal normal 14px/16px SFProText' }}
        >
          {props.title}
        </Typography>
        <WrapperTitle>
          <ProductTitle>{props?.productName}</ProductTitle>
          {shareState ? (
            <IconButton
              onClick={() => {
                handleShare(props);
              }}
            >
              <IosShareIcon style={{ color: '#999999' }} />
            </IconButton>
          ) : (
            <></>
          )}
        </WrapperTitle>
        <img
          // crossOrigin="anonymous" //disable this due to image from promobiz-photos bucket is not showing
          alt={props?.storeGfImages?.[0]?.alt}
          style={{
            objectFit: 'contain',
            width: 356,
            height: 284,
            borderRadius: 20,
            opacity: 1,
          }}
          src={`${
            props?.storeGfImages?.[0] ? props?.storeGfImages[0]?.src : noimage
          }`}
        ></img>
        <PriceWrapper>
          {/* <DiscountPrice>
            ${props?.DiscountPrice}{' '}
            {props.unit && (
              <>
                <PerUnit>Per {props.unit}</PerUnit>
              </>
            )}
          </DiscountPrice>
          <ActualPrice>${props?.ActualPrice}</ActualPrice> */}
          {props.quantity !== undefined && props.quantity > 1 ? (
            <DiscountPrice>
              {props.quantity}{' '}
              {props.unit && (
                <>
                  <PerUnit>{props.unit}</PerUnit>
                </>
              )}{' '}
              for ${props.DiscountPrice}
            </DiscountPrice>
          ) : (
            <>
              <DiscountPrice>
                ${props.DiscountPrice}{' '}
                {props.unit && (
                  <>
                    <PerUnit>Per {props.unit}</PerUnit>
                  </>
                )}
              </DiscountPrice>{' '}
              {props?.ActualPrice && (
                <ActualPrice
                  style={{
                    display:
                      props.ActualPrice &&
                      props.DiscountPrice &&
                      props.ActualPrice > props.DiscountPrice
                        ? 'inline'
                        : 'none',
                  }}
                >
                  $ {props.ActualPrice}
                </ActualPrice>
              )}
            </>
          )}
        </PriceWrapper>
        <ProductDescription>{props?.productDescription}</ProductDescription>
        <ButtonWrapper>
          {props.promoinfavlist === undefined ? (
            <ProductButton
              data-testid="new promotion detail info comp add to shopping list button"
              onClick={props.onClickAddToShoppingListButtonFnc}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="24"
                viewBox="0 0 27 24"
              >
                <text
                  id="CartWhite"
                  transform="translate(0 19)"
                  fill="#fff"
                  font-size="20"
                  font-family="SFProText"
                  font-weight="600"
                  letter-spacing="-0.024em"
                >
                  <tspan x="0" y="0">
                    􀍫
                  </tspan>
                </text>
              </svg>
              <ButtonText data-testid="Add To Shopping List">
                {t(messages.Add_To_Shopping_List())}
              </ButtonText>
            </ProductButton>
          ) : (
            <ProductButtonSelected
              data-testid="new promotion detail info comp product selected button"
              onClick={props.onClickAddToShoppingListButtonFnc}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="24"
                viewBox="0 0 28 24"
              >
                <text
                  id="CartOrange"
                  transform="translate(14 19)"
                  fill="#ff9500"
                  font-size="20"
                  font-family="SFProText"
                  font-weight="600"
                  letter-spacing="-0.024em"
                >
                  <tspan x="-13" y="0">
                    􀍫
                  </tspan>
                </text>
              </svg>
              <ButtonTextSelected data-testid="In Shopping List">
                {t(messages.In_shopping_list())}
              </ButtonTextSelected>
            </ProductButtonSelected>
          )}
        </ButtonWrapper>
      </WrapperFamilyDiv>
    );
  },
);

const WrapperFamilyDiv = styled.div`
  margin-left: 13px;
  margin-right: 13px;
  width: 356px;
  padding-top: 15px;
  border-top: 1px solid #f1f1f2;
  margin-top: 10px;
`;

const ProductTitle = styled.div`
  text-align: left;
  margin-bottom: 5px;
  font: normal normal 600 18px/25px SFProDisplay;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

const WrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ProductDescription = styled.div`
  text-align: left;
  font: normal normal normal 14px/20px SFProText;
  letter-spacing: -0.21px;
  color: #000000;
  opacity: 1;
`;
const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
`;
const DiscountPrice = styled.div`
  height: 21px;
  text-align: left;
  font: normal normal bold 18px/16px SFProText;
  letter-spacing: 0px;
  color: #ff9500;
  opacity: 1;
  margin-right: 10px;
`;
const ActualPrice = styled.div`
  height: 21px;
  text-align: left;
  text-decoration: line-through;
  font: normal normal 18px/16px SFProText;
  letter-spacing: 0px;
  color: #89888d;
  opacity: 0.5;
  font-weight: 600;
`;
const ButtonWrapper = styled.div`
  margin-top: 9px;
  margin-bottom: 27px;
`;
const ProductButton = styled.button`
   background: #ff9500 0% 0% no-repeat padding-box;
   box-shadow: 0px 3px 12px #00000029;
   border-radius: 11px;
   opacity: 1;
   width: 356px;
   height: 48px;
   outline: none;
   border: 0px;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 `;
const ProductButtonSelected = styled.button`
  width: 356px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000029;
  border: 3px solid #ff9500;
  border-radius: 11px;
  color: #ff9500;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonTextSelected = styled.span`
  font: normal normal 600 20px/24px SFProText;
  letter-spacing: -0.48px;
  color: #ff9500;
  opacity: 1;
`;
const ButtonText = styled.span`
  font: normal normal 600 20px/24px SFProText;
  letter-spacing: -0.48px;
  color: #ffffff;
`;
const PerUnit = styled.span`
  font-size: 10px;
`;
