// Path: ./src/app/components/CategoryList/index.tsx
/**
 *
 * CategoryList
 *
 */
import React, { memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ListHorizontalScroll } from '../ListHorizontalScroll';
import { Button, ButtonProps } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useHistory, useLocation } from 'react-router-dom';
import { messages } from 'locales/messages';
import * as analytics from '../../../utils/analytics';
import { styled as stlyedMUI } from '@mui/material/styles';
import './style.css';

interface Props {
  showBanner?: boolean;
}

export const CategoryList: React.FunctionComponent<Props> = memo(
  ({ showBanner }: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t, i18n } = useTranslation();
    const topCategoryData = [
      {
        id: 1,
        text: '',
        testId: 'Favorites',
        pageRoute: 'favourites',
        icon: FavoriteIcon,
      },
      {
        id: 2,
        pageRoute: 'flyers',
        text: t(messages.Explore()),
        testId: 'Explore',
      },
      {
        id: 3,
        pageRoute: 'flyer/chinesesupermarket',
        text: t(messages.chinesesupermarket()),
        testId: 'Asian Supermarket',
      },

      {
        id: 8,
        pageRoute: 'latest',
        text: t(messages.Latest()),
        testId: 'Latest',
      },
      {
        id: 4,
        pageRoute: 'flyer/electricalAppliances',
        text: t(messages.electricalAppliances()),
        testId: 'Electronics',
      },
      {
        id: 5,
        pageRoute: 'promotionlist',
        text: t(messages.Best_Deals()),
        testId: 'Best Deals',
      },
      {
        id: 6,
        pageRoute: 'flyer/restaurant',
        text: t(messages.restaurant()),
        testId: 'Restaurant',
      },
      {
        id: 7,
        pageRoute: 'flyer/grocery',
        text: t(messages.grocery()),
        testId: 'Grocery',
      },

      {
        id: 9,
        pageRoute: 'upcoming',
        text: t(messages.UpComing()),
        testId: 'UpComing',
      },
    ];
    const location = useLocation();
    let _id = 2;
    for (let index = 0; index < topCategoryData.length; index++) {
      const element = topCategoryData[index];
      if (element.pageRoute === location.pathname.split(`/`)[1]) {
        _id = element.id;
        break;
      }
    }
    const [selectedBtn, setSelectedBtn] = useState<number>(_id);
    const history = useHistory();
    async function clickButton(value) {
      setSelectedBtn(value.id);
    }
    const handleOnClick = useCallback(
      value =>
        history.push({
          pathname: `/${value.pageRoute}`,
        }),
      [history],
    );

    function addStyle(styleString) {
      const style = document.createElement('style');
      style.textContent = styleString;
      document.head.append(style);
    }

    const handlePerformSwapLeftRightOnce = () => {
      const result = sessionStorage.getItem('swapLeftRight');
      if (result !== 'done') {
        setTimeout(() => {
          addStyle(`
          .TopMenuList {
            position: relative;
            animation-name: topMenuListTab;
            animation-delay: 2s;
            animation-duration: 4s;
            animation-iteration-count: 1;
          }
          
          @keyframes topMenuListTab {
            0% {
              left: 0px;
              top: auto;
            }
            25% {
              left: -200px;
              top: auto;
            }
            50% {
              left: 0px;
              top: auto;
            }
          }
          `);
        }, 800);

        setTimeout(() => {
          addStyle(`
          .TopMenuList {
          }
          @keyframes topMenuListTab {
            
          }
          `);
          sessionStorage.setItem('swapLeftRight', 'done');
        }, 4800);
      }
    };

    React.useEffect(() => {
      handlePerformSwapLeftRightOnce();
    }, []);

    React.useEffect(() => {
      const found = topCategoryData.find(
        value => `/${value.pageRoute}` === location.pathname,
      );
      if (found) {
        setSelectedBtn(found.id);
      }
    }, [history.location]);

    React.useEffect(() => {
      const found = topCategoryData.find(
        value => `/${value.pageRoute}` === location.pathname,
      );
      let timer1: any = {};
      const result = sessionStorage.getItem('swapLeftRight');
      if (found && result === 'done') {
        /**
         * this is for when user comes back from details page of specific categroy so
         * it will show that categroy on top and scroll it into view
         */
        timer1 = setTimeout(() => {
          document.querySelector(`.category-${found.id}`)?.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center',
          });
        }, 50);
      }
      /**
       * this will clear Timeout
       * when component unmount like in willComponentUnmount
       */
      return () => {
        clearTimeout(timer1);
      };
    }, []);

    const CategoryList = topCategoryData?.map((value, key): any => (
      <MuiButton
        data-testid={'category list ' + value.testId}
        key={`${key + value.text + value.id}`}
        onClick={() => {
          analytics.click_category_list(value.text);
          clickButton(value);
          handleOnClick(value);
          document.querySelector(`.category-${value.id}`)?.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center',
          });
        }}
        variant="contained"
        startIcon={value.icon && <value.icon />}
        style={{
          color: value.id === selectedBtn ? '#007AFF' : '#707070',
          minWidth: 'fit-content',
          paddingRight: 16,
        }}
        className={`category-${value.id}`}
      >
        {value.text}
      </MuiButton>
    ));
    return (
      <ListHorizontalScroll
        top={`calc(env(safe-area-inset-top) + ${
          showBanner ? '150px' : '59px'
        })`}
        bottom={'none'}
        children={CategoryList}
        className="TopMenuList"
      ></ListHorizontalScroll>
    );
  },
);

const MuiButton = stlyedMUI(Button)<ButtonProps>(() => ({
  margin: '8px',
  backgroundColor: 'white !important',
  height: '34px',
  minWidth: '112px',
  paddingRight: '9px',
  boxShadow: '0px 3px 12px #00000029',
  borderRadius: '10px',
  textAlign: 'center',
  font: 'normal normal medium 16px/34px SF Pro Text',
  letterSpacing: '0px',
  textTransform: 'capitalize',
  opacity: '1',
  marginBottom: '20px',
  '& .MuiButton-startIcon': {
    marginLeft: 0,
    marginRight: 0,
  },
}));
