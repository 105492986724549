export async function getCityNameFromIp(ip: string) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 20000); // 20 seconds timeout

  try {
    const response = await fetch(`https://ipapi.co/${ip}/json/`, {
      signal: controller.signal,
    });
    clearTimeout(timeoutId); // Clear timeout if the fetch completes in time

    if (!response.ok) {
      throw new Error(`Failed to fetch data for IP: ${ip}`);
    }

    const result = await response.json();
    const coords =
      {
        latitude: result?.latitude,
        longitude: result?.longitude,
      } || undefined;

    return coords;
  } catch (error) {
    clearTimeout(timeoutId); // Ensure the timeout is cleared on error
    console.error('Error fetching city name:', error);
    return undefined;
  }
}

export async function getIpAddress(): Promise<string | undefined> {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 20000); // 20 seconds timeout

  try {
    const response = await fetch('https://api.ipify.org?format=json', {
      signal: controller.signal,
    });
    clearTimeout(timeoutId); // Clear timeout if the fetch completes in time

    const data = await response.json();

    if (data && data.ip) {
      return data.ip;
    }
    console.error('IP address not found in the response');
  } catch (error) {
    clearTimeout(timeoutId); // Ensure the timeout is cleared on error
    console.error('Error fetching IP address:', error);
    return undefined;
  }
}
