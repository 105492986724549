// Path: ./src/app/desktop-src/app/index.tsx
/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { MainLayout } from './layouts/Loadable';
import { DealsPage } from './pages/DealsPage/Loadable';
import { StoreProducts } from './pages/StoreProducts/Loadable';
import { FavouritesPage } from './pages/FavouritesPage/Loadable';
import { SuperMarketPage } from './pages/SuperMarketPage/Loadable';
import { FlyerTagsPage } from './pages/FlyerTagsPage/Loadable';
import { FlyerDetailPage } from './pages/FlyerDetailPage/Loadable';
import { ShoppingListPage } from './pages/ShoppingListPage/Loadable';
import { SearchPage } from './pages/SearchPage/Loadable';
import { useCustomerSlice } from 'app/desktop-src/store/CustomerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { apiService } from 'utils/api';
import {
  CreateGfCustomerDto,
  GfShoppingListDto,
  GfStoreFavouriteListDto,
} from '@swagger/typescript-fetch-goflyer';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'types';
import { Position } from '@capacitor/geolocation';
import { localstorageGet } from 'utils/localstorage';
import LogRocket from 'logrocket';
import { InfoPage } from './pages/InfoPage';
import { Spinner } from './components/Spinner';
import * as analytics from 'utils/analytics';
import { SignInPage } from './pages/SignInPage';
import { CheckUserLocationChange, GetUserLocation } from 'utils/LocationCheck';
import ReactGA from 'react-ga';
import { PrivacyPage } from './pages/PrivacyPage';
import { TermsPage } from './pages/TermsPage/Loadable';
import { FlyerRestuarantDetailPage } from './pages/FlyerRestuarantDetailPage/Loadable';
import { firebaseRemoteConfig } from 'utils/fireBaseRemoteConfig';
import { useRemoteConfigSlice } from 'app/desktop-src/store/RemoteConfigSlice';
import { SplashScreen } from '@capacitor/splash-screen';
import { LatestPage } from './pages/LatestPage';
import { UpComingPage } from './pages/UpComingPage';
import { useFlyerListSlice } from '../store/FlyerList';
import { useCommonDataSlice } from '../store/CommonSlice';
import { GfCustomerDtoWithLogin } from 'app/desktop-src/store/CustomerSlice';
import { PromotionDetailRedirect } from './pages/PromotionDetailRedirect/Loadable';
import { AboutPage } from './pages/AboutPage';
import { LanguageSwitcherParams } from 'app/components/LanguageSwitcherParams';
import { Capacitor } from '@capacitor/core';

export function DeskTopApp() {
  const {
    actions: { updateCustomer },
  } = useCustomerSlice();
  const {
    actions: { addFlyerList },
  } = useFlyerListSlice();

  const dispatch = useDispatch();
  const { actions: RemoteConfigAction } = useRemoteConfigSlice();
  const { actions: CommonDataAction } = useCommonDataSlice();

  const actions = {
    addFlyerList,
    updateCustomer,
    RemoteConfigAction,
    CommonDataAction,
  };

  const createOrUpdateCustomerIfNotLogin = async () => {
    /**
     * "When a user opens the app for the first time, the app will send a request to the backend to create a customer object. This object is then saved in local storage. The next time the user opens the app, the app checks if there is a customer object in local storage. If one is found, the app recognizes the user as a returning customer and uses the saved object from local storage."
     */
    let customer = localstorageGet('customer') as GfCustomerDtoWithLogin;
    const position = (await GetUserLocation()) as Position;

    /**
     * we don't this anymore. maybe we can use it in the future. At the beginning, we can fav a deal but not we fav the store
     */
    let gf_promotion_favourite_list: any;
    /**
     * "When the user clicks the heart button on the flyer card, the corresponding store is added to the gf_store_favourite_list. Later, when the user visits the fav tab, the app displays flyers from the stores that were added to their favorites list."
     */
    let gf_store_favourite_list: GfStoreFavouriteListDto;
    let gf_shopping_list: GfShoppingListDto;
    const customer_anonymous_uuid = uuidv4();
    if (
      !customer ||
      !customer.gfPromotionFavouriteList ||
      !customer.gfShoppingList ||
      !customer.gfStoreFavouriteList
    ) {
      gf_promotion_favourite_list =
        await apiService.gfPromotionFavouriteListControllerCreate({});
      gf_store_favourite_list =
        await apiService.gfStoreFavouriteListControllerCreate({});
      gf_shopping_list = await apiService.gfShoppingListControllerCreate({});
      const new_customer = await apiService.gfCustomerControllerCreate({
        userName: `anonymous${customer_anonymous_uuid}`,
        gfPromotionFavouriteList: { id: gf_promotion_favourite_list?.id },
        gfStoreFavouriteList: { id: gf_store_favourite_list.id },
        gfShoppingList: { id: gf_shopping_list.id },
        passwordHash: customer_anonymous_uuid,
        gfFeedBack: { id: null },
        lastLocation: {
          type: 'Point',
          coordinates: [
            position.coords.latitude.changeDecimal(3),
            position.coords.longitude.changeDecimal(3),
          ],
        },
      } as CreateGfCustomerDto);
      dispatch(updateCustomer(new_customer));
    } else {
      customer = (await apiService.gfCustomerControllerFindOne(
        customer.id,
      )) as GfCustomerDtoWithLogin;
      if (!customer) {
        console.log('desktop customer location.reload');
        localStorage.removeItem('customer');
        window.location.reload();
      }
      dispatch(updateCustomer(customer));
    }
  };
  const InitData = async () => {
    /**
     * try to login here
     * 1. for first time user, login will failed, it will generate a un-authorized customer with createOrUpdateCustomerIfNotLogin
     * 2. when customer decide to login, it will login with firebase, then it will update the existing local storage customer with either email or phone number
     */
    await CheckUserLocationChange();
    let customer = localstorageGet('customer') as GfCustomerDtoWithLogin;
    let user = await apiService.appControllerGetRequestUser({
      // credentials: 'include',
      headers: {
        access_token: customer?.access_token,
      },
    });
    /**
     * if login success, it will update customer state and local storage
     */
    if (user) {
      dispatch(
        updateCustomer({
          ...user,
          isLogin: true,
          access_token: customer?.access_token,
        }),
      );
    } else {
      /**
       * if login fail, it will go to the normal route
       */
      await createOrUpdateCustomerIfNotLogin();
    }

    try {
      if (window.config.REACT_APP_IS_DEV === `false` && customer?.id) {
        ReactGA.initialize(window.config.REACT_APP_OLD_GOOGLE_ANALYTICS, {
          debug: false,
          gaOptions: {
            siteSpeedSampleRate: 100,
            userId: customer.id,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const FireBaseRemoteConfig = async () => {
    firebaseRemoteConfig(dispatch);
  };

  useEffect(() => {
    FireBaseRemoteConfig();
  }, []);

  useEffect(() => {
    if (window.config.REACT_APP_IS_DEV === `false`) {
      try {
        LogRocket.init(window.config.REACT_APP_LOGROCKET);
      } catch (error) {
        console.log('LogRocket', error);
      }
    }
    analytics.visit_site();
    InitData();
  }, []);

  const { customer }: RootState = useSelector<RootState, RootState>(
    state => state,
  );

  const ready =
    customer !== undefined &&
    customer?.id !== undefined &&
    customer?.gfPromotionFavouriteList?.id !== undefined;

  if (Capacitor.isNativePlatform()) {
    SplashScreen.hide();
  }
  if (!ready) {
    return <Spinner data-testid="desktopapp-test-id"></Spinner>;
  }

  return (
    <BrowserRouter data-testid="desktopapp-test-id">
      <LanguageSwitcherParams />
      <Switch>
        <Route exact path="/" component={InfoPage} />
        <Route exact path="/privacy-policy" component={PrivacyPage} />
        <Route exact path="/terms-of-use" component={TermsPage} />
        <Route exact path="/about" component={AboutPage} />

        <MainLayout actions={actions}>
          <Route
            exact
            path="/flyers"
            render={props => <HomePage actions={actions} {...props} />}
          />
          <Route exact path="/flyer/:tag" component={FlyerTagsPage} />
          <Route exact path="/promotionlist" component={DealsPage} />
          {/* /promotiondetail/:id is a legacy url now. we keep here because we have google url index by that url in the past please use storedetails */}
          <Route exact path="/promotiondetail/:id" component={StoreProducts} />
          <Route exact path="/storedetails/:id" component={StoreProducts} />
          <Route
            exact
            path="/promotiondetail"
            component={PromotionDetailRedirect}
          />

          <Route exact path="/shoppinglist" component={ShoppingListPage} />
          <Route exact path="/favourites" component={FavouritesPage} />
          <Route exact path="/allflyers" component={HomePage} />
          <Route exact path="/latest" component={LatestPage} />
          <Route exact path="/upcoming" component={UpComingPage} />

          <Route exact path="/restaurants" component={SuperMarketPage} />
          <Route
            exact
            path="/restaurantview/:id"
            component={FlyerRestuarantDetailPage}
          />
          <Route exact path="/flyerview/:id" component={FlyerDetailPage} />
          <Route exact path="/search/:value" component={SearchPage} />
          <Route exact path="/signin" component={SignInPage} />

          {/* <Redirect from="/" to="/flyers" /> */}
          <Route component={NotFoundPage} />
        </MainLayout>
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}
