// Path: ./src/app/mobile-desktop-common-components/ExclusiveDealCard/index.tsx
/**
 *
 * ExclusiveDealCard
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { styled as stlyedMUI } from '@mui/material/styles';

import { GfPromotionDto, GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import { Button } from '@mui/material';
import ExclusiveLogo from '../../desktop-src/app/assets/ExclusiveLogo2.png';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CheckIcon from '@mui/icons-material/Check';
import noimage from '../../desktop-src/app/assets/No_Image.jpg';
import { messages } from 'locales/messages';
import { t } from 'i18next';

interface Props {
  Logo?: string;
  name?: string;
  description?: string;
  distance?: number;
  onClickShoppingListButton?: any;
  storeFavList?: string | undefined | GfStoreDto | GfStoreDto[];
  promotionShoppingList?:
    | string
    | undefined
    | GfPromotionDto
    | GfPromotionDto[];
  RightImage?: string;
  productName?: string;
  discountPrice?: string | number;
  price?: number;
  promotions?: [] | GfPromotionDto[] | any;
  show: boolean;
  roundedCorners?: boolean;
}

export const ExclusiveDealCard: React.FunctionComponent<Props> = memo(props => {
  return (
    <CardDiv show={props.show} roundedCorners={props.roundedCorners ?? true}>
      <TopDiv>
        <LeftDiv>
          <Img src={ExclusiveLogo} alt={props.name} />
          <SubLeftDiv>
            <Heading>{t(messages.Exclusive_Deal())}</Heading>
            <SubText>{props.name}</SubText>
          </SubLeftDiv>
        </LeftDiv>
      </TopDiv>
      {/* //here */}
      <BottomDiv>
        <ProductImage
          style={{
            backgroundImage: `url(${
              props.RightImage ? props.RightImage : noimage
            })`,
          }}
        />
        <PriceWrapper>
          {props?.price && <ActualPrice>${props.price}</ActualPrice>}
          <DiscountPrice>${props.discountPrice}</DiscountPrice>
        </PriceWrapper>
        <div>
          <MuiButton
            data-testid="exclusive deal card clck shopping list button"
            startIcon={<AddShoppingCartIcon />}
            style={{
              color: 'black',
              background:
                props.promotionShoppingList === undefined
                  ? 'transparent linear-gradient(98deg, #E6A53E 0%, #EDC366 32%, #F1CD75 47%, #E9B54E 76%, #E39E31 100%) 0% 0% no-repeat padding-box'
                  : 'white',
              border: '2px solid #EDC366',
            }}
            onClick={props.onClickShoppingListButton}
          >
            {props.promotionShoppingList === undefined ? (
              t(messages.Add_To_Cart_Shopping_List())
            ) : (
              <MuiCheckIcon />
            )}
          </MuiButton>
        </div>
      </BottomDiv>
    </CardDiv>
  );
});

interface CardDivProps {
  roundedCorners: boolean;
  show: boolean;
}

const CardDiv: any = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000040;
  border-radius: ${(props: CardDivProps) =>
    props.roundedCorners ? '20px' : '0px'};
  opacity: 1;
  height: ${(props: CardDivProps) => (props.show ? '417px' : '0px')};
  overflow: hidden;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  transition: all 1s;
  @media only screen and (max-width: 600px) {
    margin: 10px;
  }
`;

const Img = styled.img`
  height: 60px !important;
  width: 60px !important;
  border-radius: 32px !important;
  margin-left: -7px;
  object-fit: contain;
`;
const TopDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-left: 13px;
  padding-right: 13px;

  min-height: 77px;
  background: transparent
    linear-gradient(
      102deg,
      #e6a53e 0%,
      #edc366 32%,
      #f1cd75 47%,
      #e9b54e 76%,
      #e39e31 100%
    )
    0% 0% no-repeat padding-box;
`;
const LeftDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: end;
`;
const SubLeftDiv = styled.div`
  margin-left: 8px;
`;
const Heading = styled.h4`
  margin: 0px;
  text-align: left;
  font: normal normal 900 14px/16px SFProText;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  color: black;
  margin-bottom: 2px;
`;
const SubText = styled.span`
  text-align: left;
  color: black;
  font: normal normal 600 16px/19px SFProDisplay;
  letter-spacing: 0px;
  opacity: 1;
  @media (max-width: 350px) {
    font-size: 13px;
  }
`;
const BottomDiv = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;
const ProductImage = styled.div`
  background: 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  height: 214px;
  width: 214px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
`;
const PriceWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 11px;
`;
const ActualPrice = styled.span`
  text-align: left;
  text-decoration: line-through;
  font: normal normal 19px/16px SFProText;
  letter-spacing: 0px;
  color: #89888d;
  opacity: 0.5;
  padding-right: 10px;
`;
const DiscountPrice = styled.span`
  text-align: center;
  font: normal normal bold 19px/16px SFProText;
  letter-spacing: 0px;
  color: #e7a63e;
  opacity: 1;
`;

const MuiCheckIcon = stlyedMUI(CheckIcon)(() => ({
  color: '#FF9500',
  fontWeight: 'bolder',
}));
const MuiButton = stlyedMUI(Button)(() => ({
  background:
    'transparent linear-gradient(98deg, #E6A53E 0%, #EDC366 32%, #F1CD75 47%, #E9B54E 76%, #E39E31 100%) 0% 0% no-repeat padding-box',
  boxShadow: '0px 3px 12px #00000029',
  borderRadius: '11px',
  opacity: '1',
  font: 'normal normal 900 14px/16px SFProText',
  textTransform: 'capitalize',
  color: '#FFFFFF',
  height: '48px',
  paddingLeft: '20px',
  paddingRight: '20px',
  minWidth: '326px',
  '@media (max-width:350px)': {
    // eslint-disable-line no-useless-computed-key
    paddingLeft: '0px',
    paddingRight: '0px',
    fontSize: '12px',
    minWidth: '256px',
  },
}));
