// Path: ./src/app/pages/NewPromotionDetailPage/index.tsx
/**
 *
 * NewPromotionDetailPage
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { apiService } from '../../../utils/api';
import { GfPromotionDto, GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import { GetQuery } from 'utils/getQueryPara';
import ReactGA from 'react-ga';
import { Spinner } from 'app/components/Spinner';
import { GoFlyerAppContext } from 'app/store/context';
import {
  checkIfPromotionInFavList,
  checkIfStoreInFavList,
} from 'utils/dtoUtility';
import { messages } from 'locales/messages';
import { setCustomer } from 'app/store/reducer';
import { NewPromotionDetailInfoComp } from 'app/components/NewPromotionDetailInfoComp';
import { NewPromotionDetailStoreComp } from 'app/components/NewPromotionDetailStoreComp';
import * as analytics from '../../../utils/analytics';
import { useAddRemoveShoppingListMobileFunc } from 'utils/commonFunctions/AddRemoveShoppingListMobile';

export const NewPromotionDetailPage = memo(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const promotionId = GetQuery('promotionId') as string;
  const [promotion, setPromotion] = React.useState<GfPromotionDto>();
  const { state, dispatch } = React.useContext(GoFlyerAppContext);

  React.useEffect(() => {
    getPromotion();
  }, []);
  const getPromotion = async () => {
    const response = await apiService.gfPromotionControllerFindOne(promotionId);
    analytics.view_deals_detail_page(response);
    setPromotion(response);
  };
  const { clickRemoveFromShoppingListButton } =
    useAddRemoveShoppingListMobileFunc();

  if (promotion === undefined) {
    return <Spinner></Spinner>;
  }

  const promo_in_fav_list = checkIfPromotionInFavList(
    promotion,
    state.customer.gfShoppingList,
  );
  const map_center = {
    lat: promotion?.placement.store.location.coordinates[1],
    lng: promotion?.placement.store.location.coordinates[0],
  };
  async function clickFavStoreButton(
    e,
    store: GfStoreDto,
    showLikeStoreButton,
  ) {
    ReactGA.event({
      category: '1.2',
      action: `Click Store ${store.merchant.name} fav store Button`,
    });
    e.preventDefault();
    let new_store_fav_list;
    if (showLikeStoreButton) {
      new_store_fav_list =
        await apiService.gfStoreFavouriteListControllerRemoveStore(
          {
            storeId: store.id,
          },
          state.customer.gfStoreFavouriteList.id,
        );
    } else {
      new_store_fav_list =
        await apiService.gfStoreFavouriteListControllerAddStore(
          {
            storeId: store.id,
          },
          state.customer.gfStoreFavouriteList.id,
        );
    }
    const customer = state.customer;
    customer.gfStoreFavouriteList = new_store_fav_list;
    dispatch(setCustomer(customer));
  }

  const store_in_fav_list = checkIfStoreInFavList(
    promotion.placement.store,
    state.customer.gfStoreFavouriteList,
  );
  return (
    <ProductDetailContainer id="NewPromotionDetailPage">
      <NewPromotionDetailInfoComp
        promotionId={promotion.id}
        title={
          promotion.promotionTag?.length ? promotion?.promotionTag[0].name : ''
        }
        promoinfavlist={promo_in_fav_list}
        productName={promotion?.placement.product.name}
        storeGfImages={promotion?.placement.product.gfImages}
        merchantName={promotion?.placement.store.merchant.name}
        DiscountPrice={promotion?.price}
        quantity={promotion.quantity}
        unit={promotion?.unit}
        ActualPrice={promotion?.placement?.price}
        productDescription={promotion?.placement.product.description}
        onClickAddToShoppingListButtonFnc={e =>
          clickRemoveFromShoppingListButton(
            e,
            promotion,
            promo_in_fav_list !== undefined,
          )
        }
      />

      <NewPromotionDetailStoreComp
        data-testid="Deals from same store"
        store={promotion.placement.store}
        merchantLogo={promotion.placement.store.merchant.merchantLogo.src}
        merchantName={promotion.placement.store.merchant.name}
        merchantDescription={promotion.placement.store.merchant.description}
        numberOfDealsText={` ${t(messages.View())} ${
          promotion.placement.store.numberOfDeals
        } ${t(messages.Deals_From_Same_Store())}`}
        mapCenter={map_center}
        storeInfavlist={store_in_fav_list}
        onClickFavStoreButtonFnc={e => {
          clickFavStoreButton(
            e,
            promotion.placement.store,
            store_in_fav_list !== undefined,
          );
        }}
      />
    </ProductDetailContainer>
  );
});

const ProductDetailContainer = styled.div`
  margin-top: 58px !important;
  margin: 10px;
  padding-bottom: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
