// Path: ./src/app/components/BestDealCard/index.tsx
/**
 *
 * BestDealCard
 *
 */
import React, { memo } from 'react';

import styled from 'styled-components/macro';
import { messages } from 'locales/messages';
import { useTranslation } from 'react-i18next';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { GfPromotionDto, GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import { BestDealSubComponent } from '../BestDealSubComponent';
import { styled as stlyedMUI } from '@mui/material';

import noimage from '../../assets/No_Image.jpg';

interface Props {
  validStartDate: Date;
  Logo?: string;
  name?: string;
  description?: string;
  distance?: number;
  onClickShoppingListButton: any;
  onClickFavStoreIcon?: any;
  storeFavList?: string | undefined | GfStoreDto | GfStoreDto[];
  promotionShoppingList?:
    | string
    | undefined
    | GfPromotionDto
    | GfPromotionDto[];
  RightImage?: string;
  productName?: string;
  discountPrice?: number;
  quantity?: number;
  price?: number;
  promotions?: [] | GfPromotionDto[] | any;
  storeId: string;
  unit?: string;
  daysLeft?: number;
}

export const BestDealCard: React.FunctionComponent<Props> = memo(
  (props: Props) => {
    const { t } = useTranslation();

    const [loading] = React.useState<boolean>(false);
    return (
      <CardDiv>
        <TopDiv>
          <LeftDiv>
            <Img src={props.Logo} alt={props.name} />
            <SubLeftDiv>
              <Heading>{props.name}</Heading>
              <SubText>
                {props.description}
                {'  '}
                <span>
                  <MuiFiberManualRecordIcon />
                </span>
                {'  '}
                {props.distance?.toFixed(1) + ` ` + t(messages.km())}{' '}
                {props?.daysLeft !== undefined ? (
                  props?.daysLeft !== 0.1 && props?.daysLeft > 0 ? (
                    `| ${props.daysLeft} ${t(messages.Day_Left())}`
                  ) : (
                    <span
                      data-testid="Expire Today"
                      style={{ textTransform: 'capitalize' }}
                    >
                      | {t(messages.Expire_Today())}
                    </span>
                  )
                ) : (
                  <></>
                )}
              </SubText>
            </SubLeftDiv>
          </LeftDiv>
        </TopDiv>

        <BestDealSubComponent
          startingDate={props.validStartDate}
          data-testid="Best Deal Card shopping list button"
          Image={props.RightImage ? props.RightImage : noimage}
          productName={props.productName}
          promotionShoppingList={props.promotionShoppingList}
          discountPrice={props.discountPrice}
          price={props?.price}
          unit={props.unit}
          quantity={props.quantity}
          onClick={e => {
            props.onClickShoppingListButton(e);
          }}
          isLoading={loading}
          onClickCapture={() => () => {}}
        />
      </CardDiv>
    );
  },
);

const MuiFiberManualRecordIcon = stlyedMUI(FiberManualRecordIcon)(() => ({
  color: '#999999',
  fontSize: '3px',
}));
const CardDiv: any = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000040;
  border-radius: 20px;
  opacity: 1;
  min-height: 193px;
  margin-left: 13px;
  margin-right: 13px;
  margin-top: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Img = styled.img`
  height: 60px !important;
  width: 60px !important;
  border-radius: 32px !important;
  margin-left: -1px;
  object-fit: contain;
`;
const TopDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 8px;
`;
const LeftDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;
const SubLeftDiv = styled.div`
  margin-left: 8px;
`;
const Heading = styled.h4`
  margin: 0px;
  text-align: left;
  font: normal normal 600 14px/22px SFProText;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: -4px;
`;
const SubText = styled.span`
  text-align: left;
  font: normal normal normal 13px/20px SFProText;
  letter-spacing: 0px;
  color: #757575;
  opacity: 1;
`;
